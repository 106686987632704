import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import { Dialog } from "@mui/material";

import { 
  useDashboardAppDispatch,
  useDashboardAppSelector
} from "../../../hooks/useRedux";
import { DashboardRootState } from "../../../app/dashboardAppStore";
import { downloadAllCoursesSummery, getAvailableCourseOrDocConfigOptions, getCoursesHeaders } from "../clientCoursesPageSlice";
import CoursesListView from "./CoursesListView";
import { CourseHeader } from "../../../../api";
import ClientCoursesAddEdit from "../ClientCoursesAddEdit";
import { PATH_DASHBOARD } from "../../../routes/paths";

type Props = {
  clientId?: string;
  translate: Function;
};

export default function ClientCoursesListPage({
  clientId,
  translate
}: Props) {
  const navigate = useNavigate();

  const dispatch = useDashboardAppDispatch();

  const courseConfigOptions = useDashboardAppSelector(
    (state: DashboardRootState) =>
      state.clientCoursesPageSlice.courseOrDocumentConfigOptions
  );

  const coursesHeadersData = useDashboardAppSelector(
    (state: DashboardRootState) => state.clientCoursesPageSlice.coursesHeaders
  );

  const isLoading = useDashboardAppSelector(
    (state: DashboardRootState) => state.clientCoursesPageSlice.isLoading
  );

  // Add:
  const [isAddEditDialogOpen, setIsAddEditDialogOpen] = useState(false);

   // Get the data:
   useEffect(() => {
    if (clientId) {
      dispatch(getAvailableCourseOrDocConfigOptions());
      dispatch(getCoursesHeaders(clientId));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [clientId]);

  const handleOnRowClicked = (course: CourseHeader) => {
    if (clientId) {
      navigate(
        PATH_DASHBOARD.management.clients.course.path(clientId, course.id)
      );
    }
  };

  const handleOnAddNewClicked = () => {
    setIsAddEditDialogOpen(true);
  };

  const onAddCourseComplete = () => {
    if (clientId) {
      dispatch(getCoursesHeaders(clientId));
      setIsAddEditDialogOpen(false);
    }
  };

  const handleDownloadCoursesReportPdf = () => {
    dispatch(
      downloadAllCoursesSummery({
        clientId: clientId || ""
      })
    );
  };

  return (
    <>
      <CoursesListView
        translate={translate}
        isLoadingData={isLoading.generic}
        isDownloading={isLoading.download}
        coursesHeaders={coursesHeadersData || []}
        onRowClicked={handleOnRowClicked}
        titleKey="courses_page_title_courses_list"
        subtitleKey="courses_page_title_courses_list_description"
        onAddButtonClicked={handleOnAddNewClicked}
        onDownloadReportButtonClicked={handleDownloadCoursesReportPdf}
        courseOrDocumentConfigOptions={courseConfigOptions}
      />

      {/**  Add Dialog  */}
      <Dialog
        fullWidth
        maxWidth="lg"
        open={isAddEditDialogOpen}
        onClose={() => setIsAddEditDialogOpen(false)}
      >
        <ClientCoursesAddEdit
            clientId={clientId || ""}
            translate={translate}
            onComplete={onAddCourseComplete}
          />
      </Dialog>
    </>
  );
}
