import { Link } from "@mui/icons-material";

import { DocumentConfigOption, DriverCourseHeader } from "../../../../api";

import RichTableView from "../../common/views/richTableView/rich-table-view";
import { TableHeadItem } from "../../../components/table/TableHeadCustom";
import CoursesListViewRowItem from "./DriverCoursesListViewRowItem";


// ----------------------------------------------------------------------

export type DriverCoursesListViewProps = {
  translate: Function;
  titleKey?: string;
  subtitleKey?: string;
  courseConfigOptions?: DocumentConfigOption[];
  coursesHeaders: DriverCourseHeader[];
  onRowClicked: (_course: DriverCourseHeader) => void;
  isLoadingData: boolean;
  onNavigateToCoursesPage: () => void;
};

export default function DriverCoursesListView({
  translate,
  titleKey,
  subtitleKey,
  courseConfigOptions,
  coursesHeaders,
  onRowClicked,
  isLoadingData,
  onNavigateToCoursesPage,
}: DriverCoursesListViewProps) {

  const handleOnTableRowClicked = (course: DriverCourseHeader) => {
    onRowClicked(course);
  };

  const TABLE_HEAD: TableHeadItem[] = [
    {
      id: "name",
      label: `${translate("courses_table_head_title_name")}`,
    },
    {
      id: "courseType",
      label: `${translate("courses_table_head_title_type")}`,
    },
    {
      id: "singedComplete",
      label: `${translate("course_details_page_atendee_singed")}`,
      align: "center",
    },
    {
      id: "status",
      label: `${translate("course_details_page_atendee_status")}`,
      align: "center",
    },
  ];

  return (
    <RichTableView
      translate={translate}
      titleKey={titleKey}
      subtitleKey={subtitleKey}
      filteredTableData={coursesHeaders}
      tableHeads={TABLE_HEAD}
      showEndDate={false}
      isLoadingData={isLoadingData}
      randerRow={(item: DriverCourseHeader) => (
        <CoursesListViewRowItem
          translate={translate}
          key={item.id || ""}
          row={item}
          onViewRow={() => handleOnTableRowClicked(item)}
          courseOrDocumentConfigOptions={courseConfigOptions}
        />
      )}
      onActionButtonClicked={onNavigateToCoursesPage}
      actionButtonLabelKey={'driver_page_courses_navigate_to_all'}
      actionButtonIcon={<Link />}
    />
  );
}
