import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import { Box, Dialog } from "@mui/material";

import {
  useDashboardAppDispatch,
  useDashboardAppSelector,
} from "../../../hooks/useRedux";
import { DriverCourseHeader } from "../../../../api";

import { DashboardRootState } from "../../../app/dashboardAppStore";
import DriverCoursesListView from "./DriverCoursesListView";
import {
  downloadCourseDocument,
  getAvailableCourseConfigOptions,
  getDriverCoursesHeaders,
} from "../driverPageSlice";
import LoadingView from "../../../components/loading-view";
import { PATH_DASHBOARD } from "../../../routes/paths";
import { CLIENT_PAGE_TABS_IDS } from "../../clientPage/main/ClientPage";

type Props = {
  clientId?: string;
  driverId?: string;
  translate: Function;
};

export default function DriverCoursesListPage({
  clientId,
  driverId,
  translate,
}: Props) {
  const navigate = useNavigate();

  const dispatch = useDashboardAppDispatch();

  const courseConfigOptions = useDashboardAppSelector(
    (state: DashboardRootState) => state.driverPageSlice.courseConfigOptions
  );

  const isLoadingData = useDashboardAppSelector(
    (state: DashboardRootState) => state.driverPageSlice.isLoadingData
  );

  const coursesHeadersData = useDashboardAppSelector(
    (state: DashboardRootState) => state.driverPageSlice.driverCoursesHeaders
  );

  // Get the data:
  useEffect(() => {
    if (clientId && driverId) {
      dispatch(getAvailableCourseConfigOptions());
      dispatch(
        getDriverCoursesHeaders({
          driverUserId: driverId,
          includeCompletedCourses: true,
        })
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [clientId, driverId]);

  const [showDownloadDialog, setShowDownloadDialog] = useState(false);
  const handleOnRowClicked = (course: DriverCourseHeader) => {
    if (driverId && clientId) {
      setShowDownloadDialog(true);
      dispatch(
        downloadCourseDocument({
          clientId,
          courseId: course.id,
          driverId,
          onComplete: () => {
            setShowDownloadDialog(false);
          },
        })
      );
    }
  };

  const onNavigateToCoursesPage = () => {
    if (driverId && clientId) {
      navigate(
        PATH_DASHBOARD.management.clients.client(
          clientId || "",
          CLIENT_PAGE_TABS_IDS.courses
        )
      );
    }
  };

  return (
    <>
      <DriverCoursesListView
        translate={translate}
        titleKey="driver_page_courses_list_title"
        subtitleKey="driver_page_courses_list_subtitle"
        courseConfigOptions={courseConfigOptions || []}
        coursesHeaders={coursesHeadersData || []}
        onRowClicked={handleOnRowClicked}
        isLoadingData={isLoadingData.coursesHeaders}
        onNavigateToCoursesPage={onNavigateToCoursesPage}
      />

      <Dialog open={showDownloadDialog}>
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "100%",
          }}
        >
          <LoadingView showTruck />
        </Box>
      </Dialog>
    </>
  );
}
